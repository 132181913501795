<template>
  <div>
    <div class="toggle" v-for="(option, index) in options" :key="index">
      <label class="container">
        <input
          class="input"
          v-model="inputVal"
          :value="getOptionValue(option)"
          type="checkbox"
        />
        <span class="switch"></span>
        <span class="label">{{ getOptionLabel(option) }}</span>
      </label>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Toggle',
    props: {
      name: {
        type: String,
        require: true,
        default() {
          return 'checkbox_' + Math.floor(Math.random() * 100 + 1);
        }
      },
      value: {
        type: Array,
        default: null
      },
      optionLabel: {
        type: String,
        default: 'text'
      },
      options: {
        type: Array,
        default() {
          return [];
        },
        required: true
      },
      optionValue: {
        type: String,
        default: 'value'
      },
      getOptionLabel: {
        type: Function,
        default(option) {
          if (typeof option === 'object') {
            if (
              !Object.prototype.hasOwnProperty.call(option, this.optionLabel)
            ) {
              return console.warn(
                `[vue-select warn]: Label key "option.${this.optionLabel}" does not` +
                  ` exist in options object ${JSON.stringify(option)}.`
              );
            }
            return option[this.optionLabel];
          }
          return option;
        }
      },
      getOptionValue: {
        type: Function,
        default(option) {
          if (typeof option === 'object') {
            if (
              !Object.prototype.hasOwnProperty.call(option, this.optionValue)
            ) {
              return console.warn(
                `[vue-select warn]: Value key "option.${this.optionValue}" does not` +
                  ` exist in options object ${JSON.stringify(option)}.`
              );
            }
            return option[this.optionValue];
          }
          return option;
        }
      }
    },
    computed: {
      inputVal: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        }
      }
    },
    methods: {}
  };
</script>
