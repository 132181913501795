<template>
  <card
    v-if="keepHidden()"
    :metaLabel="metaLabel"
    :title="ndcDesc"
    titleTag="h4"
    v-responsive="{ small: (el) => el.width <= 425 }"
  >
    <template slot="subtitle">
      <div>{{ ndcsPurchased }} NDC Units Purchased</div>
      <div>
        {{ ndcTotalSpend | formatNumber({ format: 'dollar', places: 0 }) }}
        Total Spend
      </div>
    </template>
    <div class="card-percent-bar">
      <h5>% of Total Purchased by Account Type</h5>
      <horizontal-bar
        class="percent-by-account-type-chart"
        :chartData="percentPurchasesByAcountTypeData"
        :chartOptions="percentPurchasesByAccountTypeOptions"
        :addDataLablesPlugin="true"
      />
    </div>
    <hr class="dash-rule" />
    <toggle-select
      v-model.lazy="toggleValue"
      :value="wtAvgCostPerNdcToggles"
      :options="atOptions"
      fieldLabel="Toggle Account Type for Weighted Avg Cost Per NDC"
      class="account-toggles"
      :name="'wtAvgCostToggle_' + ndc"
      @change="$emit('adjust-weighted-avg-cost', $event)"
    />
    <div class="card-cost-bar rfs-m-4-t">
      <h5>Weighted Avg Cost Per NDC</h5>
      <div class="grid grid-cols-6 gap-4 items-center">
        <div class="col-span-4 sm:col-span-5">
          <horizontal-bar
            class="percent-by-account-type-chart"
            :chartData="wtAvgCostPerNdcData"
            :chartOptions="wtAvgCostPerNDCOptions"
            :addDataLablesPlugin="true"
          />
        </div>
        <div class="col-span-2 sm:col-span-1 grid">
          <dt class="calloutLabel">Variant</dt>
          <dd class="callout">
            <animated-number
              :number="
                wtAvgCostPerNdcVariant
                  | formatNumber({ format: 'precision', places: 0 })
              "
            ></animated-number
            >%
          </dd>
        </div>
      </div>
      <div>
        <span v-if="ndcOpportunity > 0"
          >Save
          <b class="fw-800">{{
            ndcOpportunity | formatNumber({ format: 'dollar', places: 0 })
          }}</b>
          by lowering your price to match the RWD.</span
        >
        <span v-else-if="ndcOpportunity === null"
          >Your purchasing price is better than your peers.</span
        >
        <span v-else>Your purchasing price is the same as your peers.</span>
      </div>
      <div class="flex rfs-m-8-t" style="float: right">
        <span style="font-size: 18px"> Ignore Card </span>
        <input
          style="height: 20px; width: 20px"
          v-model="isChecked"
          type="checkbox"
          name="check"
        />
      </div>
    </div>
  </card>
</template>

<script>
import card from '@/components/base/card';
import { formatNumber } from '@/js/utilities';
import HorizontalBar from '@/components/charts/HorizontalBar.vue';
import ToggleSelect from '@/components/base/forms/toggle.vue';
import AnimatedNumber from '@/components/base/animated_number.vue';
export default {
  name: 'ndcCard',
  components: {
    card,
    HorizontalBar,
    ToggleSelect,
    AnimatedNumber
  },
  props: [
    'ndc',
    'ndcDesc',
    'ndcsPurchased',
    'ndcTotalSpend',
    'percentPurchasesByAcountTypeData',
    'wtAvgCostPerNdcToggles',
    'wtAvgCostPerNdcData',
    'wtAvgCostPerNdcVariant',
    'ndcOpportunity',
    'drugGpiCd',
    'drugGpiName',
    'checked',
    'ndcArray'
  ],
  data() {
    return {
      atOptions: [
        {
          text: 'Index',
          value: 'gpo'
        },
        {
          text: 'WAC',
          value: 'wac'
        },
        {
          text: '340B',
          value: '340b'
        }
      ],
      hideCheckedNdc: false
    };
  },
  computed: {
    percentPurchasesByAccountTypeOptions() {
      return {
        plugins: {
          datalabels: {
            align: 'middle',
            textAlign: 'center',
            color: '#fff',
            display: function (context) {
              return context.dataset.data[context.dataIndex] > 5;
            },
            font: {
              weight: 'bold'
            },
            formatter: function (value, context) {
              return context.dataset.label + '\n' + value + '%';
            },
            padding: 0,
            offset: -1
          }
        },
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              display: false,
              stacked: true,
              ticks: {
                fontColor: 'white'
              },
              gridLines: {
                display: false
              }
            }
          ],
          yAxes: [
            {
              stacked: true,
              ticks: {
                fontColor: 'white'
              },
              gridLines: {
                display: false
              }
            }
          ]
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              var label = data.datasets[tooltipItem.datasetIndex].label;
              var val =
                data.datasets[tooltipItem.datasetIndex].data[
                  tooltipItem.index
                  ];
              return label + ': ' + val + '%';
            }
          }
        }
      };
    },
    wtAvgCostPerNDCOptions() {
      return {
        plugins: {
          datalabels: {
            align: function (context) {
              for (let i = 0; i < context.dataset.data.length; i++) {
                const labelLength = formatNumber(context.dataset.data[i], {
                  format: 'dollar',
                  places: 0
                }).length;
                const estLabelSize = labelLength * 6;
                // figure out the bar width
                const barWidth =
                  context.chart.getDatasetMeta(0).data[i]._model.x -
                  context.chart.getDatasetMeta(0).data[i]._model.base;
                // is the est label size greater than the bar
                const alignRight = estLabelSize > barWidth;

                // if yes align right and jump out of the function
                if (alignRight) {
                  return 'right';
                }
              }
              // if none of the data labels are longer than their bars
              return 'center';
            },
            color: '#fff',
            display: function (context) {
              return context.dataset.data[context.dataIndex];
            },
            font: {
              weight: 'bold'
            },
            formatter: function (value, context) {
              if (!value) {
                return '';
              }
              return formatNumber(value, { format: 'dollar', places: 0 });
            }
          }
        },
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              display: false,
              ticks: {
                callback: function (value) {
                  return formatNumber(value, { format: 'dollar', places: 0 });
                },
                fontColor: 'white'
              },
              gridLines: {
                display: false
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                fontColor: 'white'
              },
              gridLines: {
                display: false
              }
            }
          ]
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              var val =
                data.datasets[tooltipItem.datasetIndex].data[
                  tooltipItem.index
                  ];
              return '$' + val;
            }
          }
        }
      };
    },
    metaLabel() {
      return (
        this.ndc +
        ' ' +
        (this.drugGpiName || this.drugGpiCd
          ? ' |' +
            (this.drugGpiCd ? ' ' + this.drugGpiCd : '') +
            (this.drugGpiName ? ' ' + this.drugGpiName : '')
          : '')
      );
    },
    toggleValue: {
      get() {
        return this.wtAvgCostPerNdcToggles;
      },
      set(value) {
        this.$emit('adjust-weighted-avg-cost', value);
      }
    },
    isChecked: {
      get() {
        return this.hideCheckedNdc;
      },
      set(value) {
        this.hideCheckedNdc = value;
        this.$emit('hideCards', this.ndc, value);
      }
    }
  },
  methods: {
    hideNDCRecord() {
      this.$emit('hideCards', this.ndc, this.checked);
    },
    keepHidden() {
      this.hideCheckedNdc = this.ndcArray.indexOf(this.ndc) > -1;
      return this.ndcArray.indexOf(this.ndc) === -1 || this.checked;
    }
  }
};
</script>
