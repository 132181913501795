<template>
  <div class="card">
    <div class="card-meta-grid">
      <slot name="meta-replace" v-if="$slots['metallabel'] || metaLabel">
        <div class="card-meta-label" :class="metaLabelClass">
          <slot name="meta-label">{{ metaLabel }}</slot>
        </div>
      </slot>
      <slot name="top-right-replace" v-if="$slots['top-right-corner']">
        <div class="card-top-right-corner">
          <slot name="top-right-corner"></slot>
        </div>
      </slot>
    </div>

    <slot name="title-replace" v-if="$slots['title'] || title">
      <slot name="title"
        ><component
          :is="titleTag"
          class="card-title"
          :class="titleClass"
          v-html="title"
          >{{ title }}</component
        ></slot
      >
    </slot>

    <slot name="subtitle-replace" v-if="$slots['subtitle'] || subtitle">
      <div class="card-subtitle" :class="subtitleClass">
        <slot name="subtitle">{{ subtitle }}</slot>
      </div>
    </slot>
    <div class="card-body" :class="bodyClass">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Card',
    props: {
      metaLabelClass: String,
      metaLabel: [String, Boolean],
      titleClass: String,
      title: [String, Boolean],
      titleTag: {
        type: String,
        default: 'h3'
      },
      subtitleClass: String,
      subtitle: [String, Boolean],
      bodyClass: String
    }
  };
</script>
